<template>
  <component
    :is="isLinkValid(headerLink) ? 't3-link' : 'div'"
    v-if="header || subheader"
    class="ui-header"
    :class="themeColorClass"
    :to="isLinkValid(headerLink) ? getValidLink(headerLink) : null"
    :style="cssAlignment"
  >
    <T3HtmlParser
      v-if="header"
      :tag="headerLayoutLevel"
      class="ui-header__title"
      :class="headerSizeClass"
      :content="header"
    />

    <T3HtmlParser
      v-if="subheader"
      class="ui-header__subtitle"
      :content="subheader"
    />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiHeaderProps } from '~/types'
import useHeaderAlignment from '~ui/composables/useHeaderAlignment'
import useTheming from '~ui/composables/useTheming'
import { getValidLink, isLinkValid } from '~ui/helpers/linkUtils'

const props = withDefaults(defineProps<UiHeaderProps>(), {
  headerLayout: 1,
  headerSize: 1,
  headerPosition: 'center',
  themeColor: 'default'
})

const headerLayoutLevel = computed(() => {
  return props.tag ?? `h${props.headerLayout + 1}`
})
const headerSizeClass = computed(() => {
  return `heading-${props.headerSize + 1}`
})
const themeColorClass = useTheming(props.themeColor)
const cssAlignment = useHeaderAlignment(props)
</script>

<style lang="scss">
.ui-header {
  padding-block: var(--padding-header);

  :where([class*='heading-']) {
    margin: 0;
    margin-bottom: rem(20px);
    text-wrap: balance;

    & strong {
      font-weight: inherit;
    }

    & small {
      font-weight: 500;
    }
  }

  :where(.heading-1, .heading-2) {
    margin-bottom: rem(40px);
  }

  &__raw-title {
    margin-bottom: 0;
    text-align: var(--alignment);
  }

  &__title {
    text-align: var(--alignment);

    & em,
    & strong {
      color: var(--theme__theme-color);
    }
  }

  &__subtitle {
    font-weight: 400;
    display: block;
  }
}
</style>
