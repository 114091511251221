import { computed } from 'vue'

const useHeaderAlignment = (props: { headerPosition: string }) => {
  return computed(() => {
    const isAligmentValid = ['left', 'center', 'right'].includes(
      props.headerPosition
    )

    return {
      '--alignment': isAligmentValid ? props.headerPosition : 'left'
    } as unknown as string
  })
}

export default useHeaderAlignment
